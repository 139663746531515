import { getReq, getById, postReq } from "../../../services/api";
const state = {
  tags: [],
  allStates: [],
  company: null
};

const getters = {
  tags: (state) => state.tags,
  allStates: (state) => state.allStates,
  company: (state) => state.company
};

const actions = {
  async getCompany({ commit }, id) {
    try {
      const company = await getReq(`app/company/?company_id=${id}`);
      commit("setCompany", company[0]);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async getAllTags({ commit }, id) {
    try {
      const tags = await getReq(`app/add_tags/?company_id=${id}`);
      commit("setTags", tags);
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
    }
  },
  async getAllStates({ commit }) {
    try {
      const allStates = await getReq(`app/states/`);
      commit("setAllStates", allStates);
    } catch (error) {
      if (error.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      console.log(error.response);
      throw error.response;
    }
  },
  async retrieveDocument(_, id) {
    try {
      return await getById("app/documents/", id);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
  async sendDocs(_, payload) {
    try {
      return await postReq("app/file/", payload);
    } catch (e) {
      if (!e.response) {
        throw "No network connection";
      }
      if (e.response.status === 500) {
        throw "Internal server error";
      }
      if (e.response.status === 403) {
        throw "You are not allowed to perform this action.";
      }
      const errors = e.response.data;
      console.log(errors);
      throw errors;
    }
  },
};

const mutations = {
  setTags: (state, tags) =>
  (state.tags = [
    ...new Map(tags.map((item) => [item["add_tag"], item])).values(),
  ]),
  setAllStates: (state, allStates) => (state.allStates = allStates),
  setCompany: (state, company) => (state.company = company)
};

export default {
  state,
  getters,
  actions,
  mutations,
};
