<template>
    <div>
        <div v-if="user">
            <div class="px-[1rem] lg:px-[0rem] mt-[1rem]" id="headerSection">
                <button
                    class="text-[#fff] h-[36px] w-[110px] px-5 py-3 text-[12px] flex justify-center rounded-[4px] bg-[#2D69F6] items-center ml-auto"
                    @click="updateAllDocs(emailDocs.length > 0)">{{ emailDocs.length
                        > 0 ? 'Unselect All' : 'Select All' }}</button>

                <div class="relative my-3">
                    <input placeholder="Search for a document" v-model="searchDocs"
                        class="rounded-[12px] text-[#1B1D21] border border-[rgba(17,0,77,0.1)] px-3 py-4 w-full placeholder-[rgba(17,0,77,0.1)] focus:outline-none">
                    <svg class="absolute top-[20px] right-[15px]" width="20" height="20" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8765_2602)">
                            <path
                                d="M19.8105 18.9119L14.6468 13.8308C15.999 12.3616 16.8298 10.4187 16.8298 8.28068C16.8292 3.7071 13.0621 0 8.41471 0C3.76737 0 0.000244141 3.7071 0.000244141 8.28068C0.000244141 12.8543 3.76737 16.5614 8.41471 16.5614C10.4227 16.5614 12.2644 15.8668 13.711 14.7122L18.8947 19.8134C19.1473 20.0622 19.5573 20.0622 19.8099 19.8134C20.0631 19.5646 20.0631 19.1607 19.8105 18.9119ZM8.41471 15.2873C4.48256 15.2873 1.29493 12.1504 1.29493 8.28068C1.29493 4.41101 4.48256 1.27403 8.41471 1.27403C12.3469 1.27403 15.5345 4.41101 15.5345 8.28068C15.5345 12.1504 12.3469 15.2873 8.41471 15.2873Z"
                                fill="#E7E5ED" />
                        </g>
                        <defs>
                            <clipPath id="clip0_8765_2602">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <svg width="2" class="absolute top-[13px] right-[50px]" height="32" viewBox="0 0 2 32" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.1" d="M1 0V32" stroke="#11004D" />
                    </svg>

                </div>
            </div>
            <div>
                <div class="mt-3 overflow-y-auto" :style="[docsSectionHeight ? { 'height': docsSectionHeight +'px'} : {}]">
                    <div v-if="userDocs && userDocs.length">
                        <div class="bg-[#EAF0FE] border-b border-t border-[#F1F0F4] p-3 flex items-center justify-between">
                            <p class="mb-0 text-[18px] text-[#1B1D21] font-bold">Driver</p>
                            <p class="mb-0 text-[18px] text-[#1B1D21] font-bold">{{ user.first_name }} {{ user.last_name }}
                            </p>
                        </div>
                        <div class="flex border-[#E6E8EC] border-b items-center px-[1rem] lg:px-[0rem] py-3"
                            :class="index === 0 ? 'border-t' : ''" :key="index" v-for="(doc, index) in userDocs">
                            <div class="bg-[#EAF0FE] rounded-[50%] p-3 flex items-center justify-center w-[40px] h-[40px]">
                                <svg width="17" height="22" viewBox="0 0 17 22" v-if="doc.document.includes('.pdf')"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.53368 13.4727C4.33818 13.4727 4.20643 13.4918 4.13843 13.5109V14.7625C4.21918 14.7817 4.32011 14.787 4.4593 14.787C4.96824 14.787 5.28168 14.5298 5.28168 14.0953C5.28168 13.7064 5.0118 13.4727 4.53368 13.4727ZM8.23859 13.4854C8.02609 13.4854 7.88797 13.5045 7.80616 13.5237V16.2968C7.88797 16.3159 8.01972 16.3159 8.13872 16.3159C9.00678 16.3223 9.57203 15.8442 9.57203 14.8327C9.57841 13.9508 9.06309 13.4854 8.23859 13.4854Z"
                                        fill="#2D69F6" />
                                    <path
                                        d="M10.625 0H2.125C1.56142 0 1.02091 0.223879 0.622402 0.622402C0.22388 1.02091 0 1.56142 0 2.125V19.125C0 19.6885 0.22388 20.229 0.622402 20.6276C1.02091 21.0261 1.56142 21.25 2.125 21.25H14.875C15.4385 21.25 15.979 21.0261 16.3776 20.6276C16.7761 20.229 17 19.6885 17 19.125V6.375L10.625 0ZM5.84163 15.0769C5.51331 15.385 5.02881 15.5231 4.46462 15.5231C4.35523 15.5243 4.24589 15.5179 4.13737 15.504V17.0191H3.1875V12.8371C3.61617 12.7732 4.04931 12.7441 4.48269 12.75C5.0745 12.75 5.49525 12.8626 5.77894 13.0889C6.04881 13.3036 6.23156 13.6552 6.23156 14.0696C6.2305 14.4861 6.09238 14.8378 5.84163 15.0769ZM9.88656 16.5166C9.44031 16.8874 8.76138 17.0637 7.93156 17.0637C7.43431 17.0637 7.08262 17.0319 6.84356 17V12.8382C7.27239 12.7756 7.70536 12.7461 8.13875 12.75C8.94306 12.75 9.46581 12.8945 9.87381 13.2026C10.3148 13.5299 10.591 14.0516 10.591 14.8006C10.591 15.6113 10.2946 16.1712 9.88656 16.5166ZM13.8125 13.5681H12.1848V14.5361H13.7062V15.3159H12.1848V17.0202H11.2221V12.7819H13.8125V13.5681ZM10.625 7.4375H9.5625V2.125L14.875 7.4375H10.625Z"
                                        fill="#2D69F6" />
                                </svg>
                                <svg v-else width="17" height="22" viewBox="0 0 18 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.75 0H2.15C1.57979 0 1.03292 0.226513 0.629724 0.629724C0.226513 1.03292 0 1.57979 0 2.15V19.35C0 19.9202 0.226513 20.467 0.629724 20.8703C1.03292 21.2735 1.57979 21.5 2.15 21.5H15.05C15.6202 21.5 16.167 21.2735 16.5703 20.8703C16.9735 20.467 17.2 19.9202 17.2 19.35V6.45L10.75 0ZM5.63193 15.5294C5.63193 16.7678 5.0396 17.2 4.08607 17.2C3.86032 17.2 3.56362 17.1613 3.3712 17.0957L3.47977 16.3035C3.6163 16.3486 3.79045 16.3809 3.98395 16.3809C4.39675 16.3809 4.65368 16.1938 4.65368 15.5166V12.7828H5.633L5.63193 15.5294ZM9.17297 15.1371C8.8365 15.4521 8.342 15.595 7.7615 15.595C7.6325 15.595 7.5164 15.5875 7.4261 15.5746V17.128H6.45215V12.8409C6.89182 12.7747 7.33623 12.7449 7.78085 12.7517C8.38607 12.7517 8.81822 12.8667 9.1074 13.0989C9.38583 13.3182 9.57287 13.6794 9.57287 14.1051C9.57287 14.5308 9.4299 14.892 9.17297 15.1371ZM13.8288 16.9291C13.5278 17.0323 12.9527 17.1742 12.3786 17.1742C11.5863 17.1742 11.0123 16.9743 10.6124 16.5873C10.2136 16.2132 9.99427 15.6466 9.99965 15.0081C10.0072 13.5633 11.0564 12.7388 12.4818 12.7388C13.0419 12.7388 13.4751 12.8495 13.6869 12.9516L13.4816 13.7374C13.2429 13.6342 12.9473 13.5504 12.47 13.5504C11.6519 13.5504 11.0316 14.0148 11.0316 14.9565C11.0316 15.852 11.5939 16.3809 12.398 16.3809C12.6248 16.3809 12.8043 16.3551 12.8828 16.3153V15.4058H12.212V14.6394H13.8288V16.9291ZM10.75 7.525H9.675V2.15L15.05 7.525H10.75Z"
                                        fill="#2D69F6" />
                                    <path
                                        d="M7.8313 13.4932C7.63135 13.4932 7.4959 13.5125 7.42603 13.5319V14.8143C7.5088 14.8337 7.61308 14.8391 7.75605 14.8391C8.27635 14.8391 8.59885 14.5746 8.59885 14.1306C8.59885 13.7307 8.32258 13.4932 7.8313 13.4932Z"
                                        fill="#2D69F6" />
                                </svg>

                            </div>
                            <div class="ml-5">
                                <p class="font-bold">{{ doc.description }}</p>
                                <p class="text-[12px]" :class="doc.expiry_date ? getExpiryStatus(doc.expiry_date) : ''">
                                    {{
                                        doc.expiry_date
                                        ? "Expiring " +
                                        moment(doc.expiry_date).format("MMM DD, YYYY")
                                        : "-"
                                    }}

                                </p>
                            </div>
                            <input type="checkbox" @change="addDoc($event, doc)" :checked="emailDocs.indexOf(doc) > -1"
                                class="w-[20px] ml-auto h-[20px] text-[#2D69F6] bg-white border-[#979797] rounded focus:ring-[#2D69F6] accent-[#2D69F6]">
                        </div>
                    </div>
                    <div v-if="vehicleDocs && vehicleDocs.length && currentVehicle">
                        <div
                            class="bg-[#EAF0FE] border-b border-t border-[#F1F0F4] p-3 flex mt-3 items-center justify-between">
                            <p class="mb-0 text-[18px] text-[#1B1D21] font-bold">Vehicle</p>
                            <p class="mb-0 text-[18px] text-[#1B1D21] font-bold">{{ currentVehicle.unit_no }}</p>
                        </div>
                        <div class="flex border-[#E6E8EC] border-b items-center px-[1rem] lg:px-[0rem] py-3"
                            :class="index === 0 ? 'border-t' : ''" :key="index" v-for="(doc, index) in vehicleDocs">
                            <div class="bg-[#EAF0FE] rounded-[50%] p-3 flex items-center justify-center w-[40px] h-[40px]">
                                <svg width="17" height="22" viewBox="0 0 17 22" v-if="doc.document.includes('.pdf')"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.53368 13.4727C4.33818 13.4727 4.20643 13.4918 4.13843 13.5109V14.7625C4.21918 14.7817 4.32011 14.787 4.4593 14.787C4.96824 14.787 5.28168 14.5298 5.28168 14.0953C5.28168 13.7064 5.0118 13.4727 4.53368 13.4727ZM8.23859 13.4854C8.02609 13.4854 7.88797 13.5045 7.80616 13.5237V16.2968C7.88797 16.3159 8.01972 16.3159 8.13872 16.3159C9.00678 16.3223 9.57203 15.8442 9.57203 14.8327C9.57841 13.9508 9.06309 13.4854 8.23859 13.4854Z"
                                        fill="#2D69F6" />
                                    <path
                                        d="M10.625 0H2.125C1.56142 0 1.02091 0.223879 0.622402 0.622402C0.22388 1.02091 0 1.56142 0 2.125V19.125C0 19.6885 0.22388 20.229 0.622402 20.6276C1.02091 21.0261 1.56142 21.25 2.125 21.25H14.875C15.4385 21.25 15.979 21.0261 16.3776 20.6276C16.7761 20.229 17 19.6885 17 19.125V6.375L10.625 0ZM5.84163 15.0769C5.51331 15.385 5.02881 15.5231 4.46462 15.5231C4.35523 15.5243 4.24589 15.5179 4.13737 15.504V17.0191H3.1875V12.8371C3.61617 12.7732 4.04931 12.7441 4.48269 12.75C5.0745 12.75 5.49525 12.8626 5.77894 13.0889C6.04881 13.3036 6.23156 13.6552 6.23156 14.0696C6.2305 14.4861 6.09238 14.8378 5.84163 15.0769ZM9.88656 16.5166C9.44031 16.8874 8.76138 17.0637 7.93156 17.0637C7.43431 17.0637 7.08262 17.0319 6.84356 17V12.8382C7.27239 12.7756 7.70536 12.7461 8.13875 12.75C8.94306 12.75 9.46581 12.8945 9.87381 13.2026C10.3148 13.5299 10.591 14.0516 10.591 14.8006C10.591 15.6113 10.2946 16.1712 9.88656 16.5166ZM13.8125 13.5681H12.1848V14.5361H13.7062V15.3159H12.1848V17.0202H11.2221V12.7819H13.8125V13.5681ZM10.625 7.4375H9.5625V2.125L14.875 7.4375H10.625Z"
                                        fill="#2D69F6" />
                                </svg>
                                <svg v-else width="17" height="22" viewBox="0 0 18 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.75 0H2.15C1.57979 0 1.03292 0.226513 0.629724 0.629724C0.226513 1.03292 0 1.57979 0 2.15V19.35C0 19.9202 0.226513 20.467 0.629724 20.8703C1.03292 21.2735 1.57979 21.5 2.15 21.5H15.05C15.6202 21.5 16.167 21.2735 16.5703 20.8703C16.9735 20.467 17.2 19.9202 17.2 19.35V6.45L10.75 0ZM5.63193 15.5294C5.63193 16.7678 5.0396 17.2 4.08607 17.2C3.86032 17.2 3.56362 17.1613 3.3712 17.0957L3.47977 16.3035C3.6163 16.3486 3.79045 16.3809 3.98395 16.3809C4.39675 16.3809 4.65368 16.1938 4.65368 15.5166V12.7828H5.633L5.63193 15.5294ZM9.17297 15.1371C8.8365 15.4521 8.342 15.595 7.7615 15.595C7.6325 15.595 7.5164 15.5875 7.4261 15.5746V17.128H6.45215V12.8409C6.89182 12.7747 7.33623 12.7449 7.78085 12.7517C8.38607 12.7517 8.81822 12.8667 9.1074 13.0989C9.38583 13.3182 9.57287 13.6794 9.57287 14.1051C9.57287 14.5308 9.4299 14.892 9.17297 15.1371ZM13.8288 16.9291C13.5278 17.0323 12.9527 17.1742 12.3786 17.1742C11.5863 17.1742 11.0123 16.9743 10.6124 16.5873C10.2136 16.2132 9.99427 15.6466 9.99965 15.0081C10.0072 13.5633 11.0564 12.7388 12.4818 12.7388C13.0419 12.7388 13.4751 12.8495 13.6869 12.9516L13.4816 13.7374C13.2429 13.6342 12.9473 13.5504 12.47 13.5504C11.6519 13.5504 11.0316 14.0148 11.0316 14.9565C11.0316 15.852 11.5939 16.3809 12.398 16.3809C12.6248 16.3809 12.8043 16.3551 12.8828 16.3153V15.4058H12.212V14.6394H13.8288V16.9291ZM10.75 7.525H9.675V2.15L15.05 7.525H10.75Z"
                                        fill="#2D69F6" />
                                    <path
                                        d="M7.8313 13.4932C7.63135 13.4932 7.4959 13.5125 7.42603 13.5319V14.8143C7.5088 14.8337 7.61308 14.8391 7.75605 14.8391C8.27635 14.8391 8.59885 14.5746 8.59885 14.1306C8.59885 13.7307 8.32258 13.4932 7.8313 13.4932Z"
                                        fill="#2D69F6" />
                                </svg>

                            </div>
                            <div class="ml-5">
                                <p class="font-bold">{{ doc.description }}</p>
                                <p class="text-[12px]" :class="doc.expiry_date ? getExpiryStatus(doc.expiry_date) : ''">
                                    {{
                                        doc.expiry_date
                                        ? "Expiring " +
                                        moment(doc.expiry_date).format("MMM DD, YYYY")
                                        : "-"
                                    }}

                                </p>
                            </div>
                            <input type="checkbox" @change="addDoc($event, doc)" :checked="emailDocs.indexOf(doc) > -1"
                                class="w-[20px] ml-auto h-[20px] text-[#2D69F6] bg-white border-[#979797] rounded focus:ring-[#2D69F6] accent-[#2D69F6]">
                        </div>
                    </div>
                    <div v-if="trailerDocs && trailerDocs.length && currentTrailer">
                        <div
                            class="bg-[#EAF0FE] border-b border-t border-[#F1F0F4] p-3 flex mt-3 items-center justify-between">
                            <p class="mb-0 text-[18px] text-[#1B1D21] font-bold">Trailer</p>
                            <p class="mb-0 text-[18px] text-[#1B1D21] font-bold">{{ currentTrailer.unit_no }}</p>
                        </div>
                        <div class="flex border-[#E6E8EC] border-b items-center px-[1rem] lg:px-[0rem] py-3"
                            :class="index === 0 ? 'border-t' : ''" :key="index" v-for="(doc, index) in trailerDocs">
                            <div class="bg-[#EAF0FE] rounded-[50%] p-3 flex items-center justify-center w-[40px] h-[40px]">
                                <svg width="17" height="22" viewBox="0 0 17 22" v-if="doc.document.includes('.pdf')"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.53368 13.4727C4.33818 13.4727 4.20643 13.4918 4.13843 13.5109V14.7625C4.21918 14.7817 4.32011 14.787 4.4593 14.787C4.96824 14.787 5.28168 14.5298 5.28168 14.0953C5.28168 13.7064 5.0118 13.4727 4.53368 13.4727ZM8.23859 13.4854C8.02609 13.4854 7.88797 13.5045 7.80616 13.5237V16.2968C7.88797 16.3159 8.01972 16.3159 8.13872 16.3159C9.00678 16.3223 9.57203 15.8442 9.57203 14.8327C9.57841 13.9508 9.06309 13.4854 8.23859 13.4854Z"
                                        fill="#2D69F6" />
                                    <path
                                        d="M10.625 0H2.125C1.56142 0 1.02091 0.223879 0.622402 0.622402C0.22388 1.02091 0 1.56142 0 2.125V19.125C0 19.6885 0.22388 20.229 0.622402 20.6276C1.02091 21.0261 1.56142 21.25 2.125 21.25H14.875C15.4385 21.25 15.979 21.0261 16.3776 20.6276C16.7761 20.229 17 19.6885 17 19.125V6.375L10.625 0ZM5.84163 15.0769C5.51331 15.385 5.02881 15.5231 4.46462 15.5231C4.35523 15.5243 4.24589 15.5179 4.13737 15.504V17.0191H3.1875V12.8371C3.61617 12.7732 4.04931 12.7441 4.48269 12.75C5.0745 12.75 5.49525 12.8626 5.77894 13.0889C6.04881 13.3036 6.23156 13.6552 6.23156 14.0696C6.2305 14.4861 6.09238 14.8378 5.84163 15.0769ZM9.88656 16.5166C9.44031 16.8874 8.76138 17.0637 7.93156 17.0637C7.43431 17.0637 7.08262 17.0319 6.84356 17V12.8382C7.27239 12.7756 7.70536 12.7461 8.13875 12.75C8.94306 12.75 9.46581 12.8945 9.87381 13.2026C10.3148 13.5299 10.591 14.0516 10.591 14.8006C10.591 15.6113 10.2946 16.1712 9.88656 16.5166ZM13.8125 13.5681H12.1848V14.5361H13.7062V15.3159H12.1848V17.0202H11.2221V12.7819H13.8125V13.5681ZM10.625 7.4375H9.5625V2.125L14.875 7.4375H10.625Z"
                                        fill="#2D69F6" />
                                </svg>
                                <svg v-else width="17" height="22" viewBox="0 0 18 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.75 0H2.15C1.57979 0 1.03292 0.226513 0.629724 0.629724C0.226513 1.03292 0 1.57979 0 2.15V19.35C0 19.9202 0.226513 20.467 0.629724 20.8703C1.03292 21.2735 1.57979 21.5 2.15 21.5H15.05C15.6202 21.5 16.167 21.2735 16.5703 20.8703C16.9735 20.467 17.2 19.9202 17.2 19.35V6.45L10.75 0ZM5.63193 15.5294C5.63193 16.7678 5.0396 17.2 4.08607 17.2C3.86032 17.2 3.56362 17.1613 3.3712 17.0957L3.47977 16.3035C3.6163 16.3486 3.79045 16.3809 3.98395 16.3809C4.39675 16.3809 4.65368 16.1938 4.65368 15.5166V12.7828H5.633L5.63193 15.5294ZM9.17297 15.1371C8.8365 15.4521 8.342 15.595 7.7615 15.595C7.6325 15.595 7.5164 15.5875 7.4261 15.5746V17.128H6.45215V12.8409C6.89182 12.7747 7.33623 12.7449 7.78085 12.7517C8.38607 12.7517 8.81822 12.8667 9.1074 13.0989C9.38583 13.3182 9.57287 13.6794 9.57287 14.1051C9.57287 14.5308 9.4299 14.892 9.17297 15.1371ZM13.8288 16.9291C13.5278 17.0323 12.9527 17.1742 12.3786 17.1742C11.5863 17.1742 11.0123 16.9743 10.6124 16.5873C10.2136 16.2132 9.99427 15.6466 9.99965 15.0081C10.0072 13.5633 11.0564 12.7388 12.4818 12.7388C13.0419 12.7388 13.4751 12.8495 13.6869 12.9516L13.4816 13.7374C13.2429 13.6342 12.9473 13.5504 12.47 13.5504C11.6519 13.5504 11.0316 14.0148 11.0316 14.9565C11.0316 15.852 11.5939 16.3809 12.398 16.3809C12.6248 16.3809 12.8043 16.3551 12.8828 16.3153V15.4058H12.212V14.6394H13.8288V16.9291ZM10.75 7.525H9.675V2.15L15.05 7.525H10.75Z"
                                        fill="#2D69F6" />
                                    <path
                                        d="M7.8313 13.4932C7.63135 13.4932 7.4959 13.5125 7.42603 13.5319V14.8143C7.5088 14.8337 7.61308 14.8391 7.75605 14.8391C8.27635 14.8391 8.59885 14.5746 8.59885 14.1306C8.59885 13.7307 8.32258 13.4932 7.8313 13.4932Z"
                                        fill="#2D69F6" />
                                </svg>

                            </div>
                            <div class="ml-5">
                                <p class="font-bold">{{ doc.description }}</p>
                                <p class="text-[12px]" :class="doc.expiry_date ? getExpiryStatus(doc.expiry_date) : ''">
                                    {{
                                        doc.expiry_date
                                        ? "Expiring " +
                                        moment(doc.expiry_date).format("MMM DD, YYYY")
                                        : "-"
                                    }}

                                </p>
                            </div>
                            <input type="checkbox" @change="addDoc($event, doc)" :checked="emailDocs.indexOf(doc) > -1"
                                class="w-[20px] ml-auto h-[20px] text-[#2D69F6] bg-white border-[#979797] rounded focus:ring-[#2D69F6] accent-[#2D69F6]">
                        </div>
                    </div>
                    <div v-if="companyDocs && companyDocs.length">
                        <div
                            class="bg-[#EAF0FE] border-b border-t border-[#F1F0F4] p-3 flex mt-3 items-center justify-between">
                            <p class="mb-0 text-[18px] text-[#1B1D21] font-bold">Company</p>
                            <!-- <p class="mb-0 text-[18px] text-[#1B1D21] font-bold">{{ company.name }}</p> -->
                        </div>
                        <div class="flex border-[#E6E8EC] border-b items-center px-[1rem] lg:px-[0rem] py-3"
                            :class="index === 0 ? 'border-t' : ''" :key="index" v-for="(doc, index) in company.docs">
                            <div class="bg-[#EAF0FE] rounded-[50%] p-3 flex items-center justify-center w-[40px] h-[40px]">
                                <svg width="17" height="22" viewBox="0 0 17 22" v-if="doc.document.includes('.pdf')"
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.53368 13.4727C4.33818 13.4727 4.20643 13.4918 4.13843 13.5109V14.7625C4.21918 14.7817 4.32011 14.787 4.4593 14.787C4.96824 14.787 5.28168 14.5298 5.28168 14.0953C5.28168 13.7064 5.0118 13.4727 4.53368 13.4727ZM8.23859 13.4854C8.02609 13.4854 7.88797 13.5045 7.80616 13.5237V16.2968C7.88797 16.3159 8.01972 16.3159 8.13872 16.3159C9.00678 16.3223 9.57203 15.8442 9.57203 14.8327C9.57841 13.9508 9.06309 13.4854 8.23859 13.4854Z"
                                        fill="#2D69F6" />
                                    <path
                                        d="M10.625 0H2.125C1.56142 0 1.02091 0.223879 0.622402 0.622402C0.22388 1.02091 0 1.56142 0 2.125V19.125C0 19.6885 0.22388 20.229 0.622402 20.6276C1.02091 21.0261 1.56142 21.25 2.125 21.25H14.875C15.4385 21.25 15.979 21.0261 16.3776 20.6276C16.7761 20.229 17 19.6885 17 19.125V6.375L10.625 0ZM5.84163 15.0769C5.51331 15.385 5.02881 15.5231 4.46462 15.5231C4.35523 15.5243 4.24589 15.5179 4.13737 15.504V17.0191H3.1875V12.8371C3.61617 12.7732 4.04931 12.7441 4.48269 12.75C5.0745 12.75 5.49525 12.8626 5.77894 13.0889C6.04881 13.3036 6.23156 13.6552 6.23156 14.0696C6.2305 14.4861 6.09238 14.8378 5.84163 15.0769ZM9.88656 16.5166C9.44031 16.8874 8.76138 17.0637 7.93156 17.0637C7.43431 17.0637 7.08262 17.0319 6.84356 17V12.8382C7.27239 12.7756 7.70536 12.7461 8.13875 12.75C8.94306 12.75 9.46581 12.8945 9.87381 13.2026C10.3148 13.5299 10.591 14.0516 10.591 14.8006C10.591 15.6113 10.2946 16.1712 9.88656 16.5166ZM13.8125 13.5681H12.1848V14.5361H13.7062V15.3159H12.1848V17.0202H11.2221V12.7819H13.8125V13.5681ZM10.625 7.4375H9.5625V2.125L14.875 7.4375H10.625Z"
                                        fill="#2D69F6" />
                                </svg>
                                <svg v-else width="17" height="22" viewBox="0 0 18 22" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.75 0H2.15C1.57979 0 1.03292 0.226513 0.629724 0.629724C0.226513 1.03292 0 1.57979 0 2.15V19.35C0 19.9202 0.226513 20.467 0.629724 20.8703C1.03292 21.2735 1.57979 21.5 2.15 21.5H15.05C15.6202 21.5 16.167 21.2735 16.5703 20.8703C16.9735 20.467 17.2 19.9202 17.2 19.35V6.45L10.75 0ZM5.63193 15.5294C5.63193 16.7678 5.0396 17.2 4.08607 17.2C3.86032 17.2 3.56362 17.1613 3.3712 17.0957L3.47977 16.3035C3.6163 16.3486 3.79045 16.3809 3.98395 16.3809C4.39675 16.3809 4.65368 16.1938 4.65368 15.5166V12.7828H5.633L5.63193 15.5294ZM9.17297 15.1371C8.8365 15.4521 8.342 15.595 7.7615 15.595C7.6325 15.595 7.5164 15.5875 7.4261 15.5746V17.128H6.45215V12.8409C6.89182 12.7747 7.33623 12.7449 7.78085 12.7517C8.38607 12.7517 8.81822 12.8667 9.1074 13.0989C9.38583 13.3182 9.57287 13.6794 9.57287 14.1051C9.57287 14.5308 9.4299 14.892 9.17297 15.1371ZM13.8288 16.9291C13.5278 17.0323 12.9527 17.1742 12.3786 17.1742C11.5863 17.1742 11.0123 16.9743 10.6124 16.5873C10.2136 16.2132 9.99427 15.6466 9.99965 15.0081C10.0072 13.5633 11.0564 12.7388 12.4818 12.7388C13.0419 12.7388 13.4751 12.8495 13.6869 12.9516L13.4816 13.7374C13.2429 13.6342 12.9473 13.5504 12.47 13.5504C11.6519 13.5504 11.0316 14.0148 11.0316 14.9565C11.0316 15.852 11.5939 16.3809 12.398 16.3809C12.6248 16.3809 12.8043 16.3551 12.8828 16.3153V15.4058H12.212V14.6394H13.8288V16.9291ZM10.75 7.525H9.675V2.15L15.05 7.525H10.75Z"
                                        fill="#2D69F6" />
                                    <path
                                        d="M7.8313 13.4932C7.63135 13.4932 7.4959 13.5125 7.42603 13.5319V14.8143C7.5088 14.8337 7.61308 14.8391 7.75605 14.8391C8.27635 14.8391 8.59885 14.5746 8.59885 14.1306C8.59885 13.7307 8.32258 13.4932 7.8313 13.4932Z"
                                        fill="#2D69F6" />
                                </svg>

                            </div>
                            <div class="ml-5">
                                <p class="font-bold">{{ doc.description }}</p>
                                <p class="text-[12px]" :class="doc.expiry_date ? getExpiryStatus(doc.expiry_date) : ''">
                                    {{
                                        doc.expiry_date
                                        ? "Expiring " +
                                        moment(doc.expiry_date).format("MMM DD, YYYY")
                                        : "-"
                                    }}

                                </p>
                            </div>
                            <input type="checkbox" @change="addDoc($event, doc)" :checked="emailDocs.indexOf(doc) > -1"
                                class="w-[20px] ml-auto h-[20px] text-[#2D69F6] bg-white border-[#979797] rounded focus:ring-[#2D69F6] accent-[#2D69F6]">
                        </div>
                    </div>
                </div>
                <form class="px-[1rem] lg:px-[0rem]" @submit.prevent="sendEmail()">
                    <div class="relative my-3">
                        <button
                            class="absolute z-[11] top-[15px] flex right-[10px] items-center ps-3 cursor-pointer bg-transparent border-transparent"><svg
                                width="20" viewBox="0 0 20 20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_8765_4160)">
                                    <path
                                        d="M18.4177 8.80125L2.29412 0.936052C2.15545 0.86841 2.00318 0.833252 1.84889 0.833252C1.28804 0.833252 0.833374 1.28792 0.833374 1.84877V1.87808C0.833374 2.01434 0.850082 2.1501 0.883132 2.2823L2.42977 8.46884C2.47202 8.63784 2.61489 8.76267 2.78799 8.78192L9.58596 9.53725C9.82171 9.56342 10 9.76267 10 9.99992C10 10.2372 9.82171 10.4364 9.58596 10.4626L2.78799 11.2179C2.61489 11.2372 2.47202 11.362 2.42977 11.531L0.883132 17.7175C0.850082 17.8498 0.833374 17.9855 0.833374 18.1218V18.1511C0.833374 18.7119 1.28804 19.1666 1.84889 19.1666C2.00318 19.1666 2.15545 19.1314 2.29412 19.0638L18.4177 11.1986C18.876 10.9751 19.1667 10.5098 19.1667 9.99992C19.1667 9.49 18.876 9.02475 18.4177 8.80125Z"
                                        fill="#2D69F6" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_8765_4160">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                        <input type="email" id="floating_outlined"
                            class="rounded-[16px] block px-2.5 pb-2.5 pt-4 w-full text-[12px] bg-transparent rounded-lg border border-[#DADADA] appearance-none focus:outline-none focus:ring-0 focus:border-[#2D69F6] peer"
                            placeholder="" required v-model="email" />
                        <label for="floating_outlined"
                            class="absolute text-[12px] text-[#DADADA] duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 start-1">Email
                            Address</label>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import * as moment from "moment-timezone";
export default {
    name: "EmailDocumentsComponent",
    data() {
        return {
            moment,
            email: "",
            searchDocs: "",
            emailDocs: [],
            docsSectionHeight:null
        }
    },
    computed: {
        ...mapGetters(['user', "currentVehicle", "currentTrailer", "company"]),
        userDocs() {
            if (this.searchDocs) {
                return this.user?.team_member.docs.filter(doc => doc.description.toLowerCase().includes(this.searchDocs.toLowerCase()));
            }
            return this.user?.team_member.docs
        },
        vehicleDocs() {
            if (this.currentVehicle) {
                if (this.searchDocs) {
                    return this.currentVehicle?.docs.filter(doc => doc.description.toLowerCase().includes(this.searchDocs.toLowerCase()));
                }
                return this.currentVehicle?.docs
            }
            return []
        },
        trailerDocs() {
            if (this.currentTrailer) {
                if (this.searchDocs) {
                    return this.currentTrailer?.docs.filter(doc => doc.description.toLowerCase().includes(this.searchDocs.toLowerCase()));
                }
                return this.currentTrailer?.docs
            }
            return []
        },
        companyDocs() {
            if (this.searchDocs) {
                return this.company?.docs.filter(doc => doc.description.toLowerCase().includes(this.searchDocs.toLowerCase()));
            }
            return this.company?.docs
        },
    },
    watch: {
        searchDocs() {
            if (this.searchDocs) {
                this.emailDocs = []
            }
        }
    },
    methods: {
        ...mapActions(["sendDocs", "retrieveDocument", 'setNotification', 'setLoader']),
        getExtensionFromMimeType(mimeType) {
            const mimeMap = {
                'application/pdf': 'pdf',
                'image/jpeg': 'jpg',
                'image/png': 'png',
            };

            return mimeMap[mimeType] || '';
        },

        async sendEmail() {
            if (this.emailDocs.length) {
                this.setLoader(true)
                const userSelectedDocs = [], vehicleSelectedDocs = [], trailerSelectedDocs = [], companySelectedDocs = []
                this.emailDocs.map((doc) => {
                    if (this.userDocs.some(docObj => docObj.id === doc.id)) {
                        userSelectedDocs.push({ ...doc, type: 'driver' })
                    }
                    else if (this.vehicleDocs.some(docObj => docObj.id === doc.id)) {
                        vehicleSelectedDocs.push({ ...doc, type: 'vehicle' })
                    }
                    else if (this.trailerDocs.some(docObj => docObj.id === doc.id)) {
                        trailerSelectedDocs.push({ ...doc, type: 'trailer' })
                    }
                    else {
                        companySelectedDocs.push({ ...doc, type: 'company' })
                    }
                })
                const formData = new FormData()
                const promises = [...userSelectedDocs, ...vehicleSelectedDocs, ...trailerSelectedDocs, ...companySelectedDocs].map(async (doc) => {
                    const { document } = await this.retrieveDocument(doc.id);
                    await fetch(document)
                        .then(response => response.blob())
                        .then(blob => {
                            let fileName;
                            if (doc.type === 'driver') {
                                fileName = `${this.user.first_name}${this.user.last_name}-${doc.description}.${this.getExtensionFromMimeType(blob.type)}`
                            }
                            else if (doc.type === 'vehicle') {
                                fileName = `${this.currentVehicle.unit_no}-${doc.description}.${this.getExtensionFromMimeType(blob.type)}`
                            }
                            else if (doc.type === 'trailer') {
                                fileName = `${this.currentTrailer.unit_no}-${doc.description}.${this.getExtensionFromMimeType(blob.type)}`
                            }
                            else {
                                fileName = `${this.company.name}-${doc.description}.${this.getExtensionFromMimeType(blob.type)}`
                            }
                            const file = new File([blob], fileName, {
                                type: blob.type
                            });
                            formData.append("file", file)
                        })
                })
                await Promise.all(promises).then(async () => {

                    formData.append("email", this.email)
                    formData.append("driver", `${this.user.first_name} ${this.user.last_name}`)
                    formData.append("company", this.company.name)
                    try {
                        await this.sendDocs(formData)
                        this.setNotification({
                            msg: "Email Sent Successfully",
                            type: "success",
                            color: "green",
                        })
                        this.emailDocs = []
                        this.email = ''
                        this.setLoader(false)
                        this.$router.push("/")
                    }
                    catch (e) {
                        this.setLoader(false)
                        console.log(e)
                    }
                }).catch(() => {
                    this.setLoader(false)
                })
            } else {
                this.setNotification({
                    msg: 'Please select atleast one document',
                    type: "error",
                    color: "red",
                });
            }
        },
        updateAllDocs(status) {
            if (status) {
                this.emailDocs = []
            }
            else {
                this.emailDocs = [
                    ...this.vehicleDocs,
                    ...this.userDocs,
                    ...this.trailerDocs,
                    ...this.companyDocs
                ]
            }
        },
        addDoc(event, doc) {
            if (event.target.checked) {
                this.emailDocs.push(doc)
            }
            else {
                const index = this.emailDocs.indexOf(doc)
                this.emailDocs.splice(index, 1)
            }
        },
        getExpiryStatus(date) {
            const currentDate = new Date();
            const expiryDate = new Date(date);
            const diffDays = parseInt(
                (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                10
            );
            let color;
            if (diffDays > 31) {
                color = "text-[#23262F]";
            }
            else if (diffDays <= 31 && diffDays >= 1) {
                color = "text-[#FEC701]";
            }
            else color = "text-[#FF0000]";
            return color
        },
        updateDocAreaSize() {
            this.docsSectionHeight = document.querySelector("body").clientHeight - 270
        }
    },
    destroyed() {
        document.querySelector('body').classList.remove("overflow-y-hidden")
        window.removeEventListener("resize", this.updateDocAreaSize);
    },
    mounted(){
        this.updateDocAreaSize()
        document.querySelector('body').classList.add("overflow-y-hidden")
        window.addEventListener("resize", this.updateDocAreaSize);
    }
}
</script>