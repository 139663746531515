<template>
    <div>
        <div v-if="company">
            <div class="px-[1rem] lg:px-[0rem] mt-[1rem]">
                <div class="flex items-center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8765_568)">
                            <path
                                d="M13.7172 9.5752L21.0589 11.0335C21.6505 11.1585 22.0505 11.6585 22.0505 12.2502V20.7502C22.0505 21.4419 21.4922 22.0002 20.8005 22.0002H13.3005C13.5339 22.0002 13.7172 21.8169 13.7172 21.5835V21.1669H20.8005C21.0255 21.1669 21.2172 20.9835 21.2172 20.7502V12.2502C21.2172 12.0585 21.0839 11.8835 20.8922 11.8419L13.7172 10.4252V9.5752Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M18.3006 13.667C18.534 13.667 18.7173 13.8503 18.7173 14.0837C18.7173 14.317 18.534 14.5003 18.3006 14.5003H16.634C16.4006 14.5003 16.2173 14.317 16.2173 14.0837C16.2173 13.8503 16.4006 13.667 16.634 13.667H18.3006Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M18.3006 16.167C18.534 16.167 18.7173 16.3503 18.7173 16.5837C18.7173 16.817 18.534 17.0003 18.3006 17.0003H16.634C16.4006 17.0003 16.2173 16.817 16.2173 16.5837C16.2173 16.3503 16.4006 16.167 16.634 16.167H18.3006Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M18.3006 18.667C18.534 18.667 18.7173 18.8503 18.7173 19.0837C18.7173 19.317 18.534 19.5003 18.3006 19.5003H16.634C16.4006 19.5003 16.2173 19.317 16.2173 19.0837C16.2173 18.8503 16.4006 18.667 16.634 18.667H18.3006Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M13.7171 21.5832C13.7171 21.8165 13.5338 21.9999 13.3005 21.9999C13.0671 21.9999 12.8838 21.8165 12.8838 21.5832V21.1665V9.91654C12.8838 9.79154 12.9421 9.67487 13.0338 9.59154C13.1338 9.51654 13.2588 9.48321 13.3838 9.50821L13.7171 9.57487V10.4249V21.1665V21.5832Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M12.884 21.167V21.5837C12.884 21.817 13.0673 22.0003 13.3006 22.0003H9.96729C10.2006 22.0003 10.384 21.817 10.384 21.5837V21.167H12.884Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M10.8006 6.16699C11.034 6.16699 11.2173 6.35033 11.2173 6.58366C11.2173 6.81699 11.034 7.00033 10.8006 7.00033H9.13395C8.90062 7.00033 8.71729 6.81699 8.71729 6.58366C8.71729 6.35033 8.90062 6.16699 9.13395 6.16699H10.8006Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M11.2173 9.08366C11.2173 9.31699 11.034 9.50033 10.8006 9.50033H9.13395C8.90062 9.50033 8.71729 9.31699 8.71729 9.08366C8.71729 8.85033 8.90062 8.66699 9.13395 8.66699H10.8006C11.034 8.66699 11.2173 8.85033 11.2173 9.08366Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M10.8006 11.167C11.034 11.167 11.2173 11.3503 11.2173 11.5837C11.2173 11.817 11.034 12.0003 10.8006 12.0003H9.13395C8.90062 12.0003 8.71729 11.817 8.71729 11.5837C8.71729 11.3503 8.90062 11.167 9.13395 11.167H10.8006Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M10.8006 13.667C11.034 13.667 11.2173 13.8503 11.2173 14.0837C11.2173 14.317 11.034 14.5003 10.8006 14.5003H9.13395C8.90062 14.5003 8.71729 14.317 8.71729 14.0837C8.71729 13.8503 8.90062 13.667 9.13395 13.667H10.8006Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M7.05054 14.0837C7.05054 14.317 6.8672 14.5003 6.63387 14.5003H4.9672C4.73387 14.5003 4.55054 14.317 4.55054 14.0837C4.55054 13.8503 4.73387 13.667 4.9672 13.667H6.63387C6.8672 13.667 7.05054 13.8503 7.05054 14.0837Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M6.63387 6.16699C6.8672 6.16699 7.05054 6.35033 7.05054 6.58366C7.05054 6.81699 6.8672 7.00033 6.63387 7.00033H4.9672C4.73387 7.00033 4.55054 6.81699 4.55054 6.58366C4.55054 6.35033 4.73387 6.16699 4.9672 6.16699H6.63387Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M6.63387 8.66699C6.8672 8.66699 7.05054 8.85033 7.05054 9.08366C7.05054 9.31699 6.8672 9.50033 6.63387 9.50033H4.9672C4.73387 9.50033 4.55054 9.31699 4.55054 9.08366C4.55054 8.85033 4.73387 8.66699 4.9672 8.66699H6.63387Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M6.63387 11.167C6.8672 11.167 7.05054 11.3503 7.05054 11.5837C7.05054 11.817 6.8672 12.0003 6.63387 12.0003H4.9672C4.73387 12.0003 4.55054 11.817 4.55054 11.5837C4.55054 11.3503 4.73387 11.167 4.9672 11.167H6.63387Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M9.55046 17.417C9.55046 17.1837 9.35879 17.0003 9.13379 17.0003H6.63379C6.40046 17.0003 6.21712 17.1837 6.21712 17.417V21.167H5.38379V17.417C5.38379 16.7253 5.94212 16.167 6.63379 16.167H9.13379C9.82546 16.167 10.3838 16.7253 10.3838 17.417V21.167H9.55046V17.417Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M6.21712 21.167H9.55046H10.3838V21.5837C10.3838 21.817 10.2005 22.0003 9.96712 22.0003H5.80046C5.56712 22.0003 5.38379 21.817 5.38379 21.5837V21.167H6.21712Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                            <path
                                d="M3.50887 2.16661L12.6672 3.55828C13.2755 3.65828 13.7172 4.17495 13.7172 4.79161V9.57495L13.3839 9.50828C13.2589 9.48328 13.1339 9.51661 13.0339 9.59161C12.9422 9.67495 12.8839 9.79161 12.8839 9.91661V4.79161C12.8839 4.58328 12.7339 4.40828 12.5339 4.37495L3.37554 2.99161C3.35054 2.98328 3.32554 2.98328 3.30054 2.98328C3.20054 2.98328 3.10887 3.01661 3.03387 3.08328C2.93387 3.16661 2.88387 3.27495 2.88387 3.39995V20.7499C2.88387 20.9833 3.07554 21.1666 3.30054 21.1666H5.38387V21.5833C5.38387 21.8166 5.5672 21.9999 5.80054 21.9999H3.30054C2.60887 21.9999 2.05054 21.4416 2.05054 20.7499V3.39995C2.05054 3.03328 2.20887 2.68328 2.4922 2.44995C2.77554 2.20828 3.1422 2.10828 3.50887 2.16661Z"
                                fill="#2D69F6" stroke="#2D69F6" stroke-width="0.5" />
                        </g>
                        <defs>
                            <clipPath id="clip0_8765_568">
                                <rect width="20" height="20" fill="white" transform="translate(2.05054 2)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div class="ml-5 w-full">
                        <p class="text-[14px] opacity-50">Name</p>
                        <p class="text-[#1B1D21]">{{ company.name }}</p>
                        <hr class="mt-3">
                    </div>
                </div>
                <div class="flex mt-5 items-center">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8765_665)">
                            <path
                                d="M10.0001 16.4284C13.5505 16.4284 16.4287 13.5503 16.4287 9.99986C16.4287 6.44946 13.5505 3.57129 10.0001 3.57129C6.4497 3.57129 3.57153 6.44946 3.57153 9.99986C3.57153 13.5503 6.4497 16.4284 10.0001 16.4284Z"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                                d="M10.0002 10.7137C10.3947 10.7137 10.7145 10.3939 10.7145 9.99944C10.7145 9.60496 10.3947 9.28516 10.0002 9.28516C9.60569 9.28516 9.28589 9.60496 9.28589 9.99944C9.28589 10.3939 9.60569 10.7137 10.0002 10.7137Z"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 3.57101V0.713867" stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M10 19.2849V16.4277" stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M16.4287 10H19.2859" stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M0.714355 10H3.5715" stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_8765_665">
                                <rect width="20" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div class="ml-5 w-full">
                        <p class="text-[14px] opacity-50">Address</p>
                        <p class="text-[#1B1D21]" v-html="companyAddress"></p>
                        <hr class="mt-3">
                    </div>
                </div>
                <div class="flex mt-5 items-center">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_8765_678)">
                            <path
                                d="M9.49696 20.5452C8.61904 21.1115 7.57288 21.3584 6.53438 21.2442C5.4959 21.1299 4.5284 20.6616 3.7946 19.9179L3.15308 19.2906C2.87183 19.0029 2.71436 18.6166 2.71436 18.2144C2.71436 17.8121 2.87183 17.4256 3.15308 17.1381L5.87596 14.4437C6.16123 14.1633 6.54518 14.0062 6.94516 14.0062C7.34511 14.0062 7.72908 14.1633 8.01434 14.4437C8.30201 14.7249 8.68836 14.8824 9.09067 14.8824C9.49298 14.8824 9.87931 14.7249 10.167 14.4437L14.4438 10.1669C14.5864 10.0263 14.6997 9.85873 14.7771 9.67396C14.8544 9.48919 14.8942 9.29087 14.8942 9.09057C14.8942 8.89026 14.8544 8.69196 14.7771 8.50717C14.6997 8.3224 14.5864 8.15484 14.4438 8.01424C14.1634 7.72899 14.0063 7.34502 14.0063 6.94506C14.0063 6.54509 14.1634 6.16113 14.4438 5.87586L17.1524 3.16724C17.4401 2.88599 17.8264 2.72852 18.2286 2.72852C18.6311 2.72852 19.0174 2.88599 19.3051 3.16724L19.9324 3.80876C20.6759 4.54256 21.1442 5.51006 21.2585 6.54854C21.3728 7.58704 21.1259 8.6332 20.5595 9.51112C17.6085 13.8602 13.8537 17.6054 9.49696 20.5452Z"
                                stroke="#2D69F6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_8765_678">
                                <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div class="ml-5 w-full">
                        <p class="text-[14px] opacity-50">Phone</p>
                        <p class="text-[#1B1D21]">{{ company.phone ? company.phone : '-' }}</p>
                        <hr class="mt-3">
                    </div>
                </div>
            </div>
            <div class="flex border-[#E6E8EC] border-b items-center px-[1rem] lg:px-[0rem] py-3 cursor-pointer" @click="openDoc(doc.id)"
                :class="index === 0 ? 'border-t mt-5' : ''" :key="index" v-for="(doc, index) in company.docs">
                <div class="bg-[#EAF0FE] rounded-[50%] p-3 flex items-center justify-center w-[40px] h-[40px]">
                    <svg width="17" height="22" viewBox="0 0 17 22" v-if="doc.document.includes('.pdf')" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4.53368 13.4727C4.33818 13.4727 4.20643 13.4918 4.13843 13.5109V14.7625C4.21918 14.7817 4.32011 14.787 4.4593 14.787C4.96824 14.787 5.28168 14.5298 5.28168 14.0953C5.28168 13.7064 5.0118 13.4727 4.53368 13.4727ZM8.23859 13.4854C8.02609 13.4854 7.88797 13.5045 7.80616 13.5237V16.2968C7.88797 16.3159 8.01972 16.3159 8.13872 16.3159C9.00678 16.3223 9.57203 15.8442 9.57203 14.8327C9.57841 13.9508 9.06309 13.4854 8.23859 13.4854Z"
                            fill="#2D69F6" />
                        <path
                            d="M10.625 0H2.125C1.56142 0 1.02091 0.223879 0.622402 0.622402C0.22388 1.02091 0 1.56142 0 2.125V19.125C0 19.6885 0.22388 20.229 0.622402 20.6276C1.02091 21.0261 1.56142 21.25 2.125 21.25H14.875C15.4385 21.25 15.979 21.0261 16.3776 20.6276C16.7761 20.229 17 19.6885 17 19.125V6.375L10.625 0ZM5.84163 15.0769C5.51331 15.385 5.02881 15.5231 4.46462 15.5231C4.35523 15.5243 4.24589 15.5179 4.13737 15.504V17.0191H3.1875V12.8371C3.61617 12.7732 4.04931 12.7441 4.48269 12.75C5.0745 12.75 5.49525 12.8626 5.77894 13.0889C6.04881 13.3036 6.23156 13.6552 6.23156 14.0696C6.2305 14.4861 6.09238 14.8378 5.84163 15.0769ZM9.88656 16.5166C9.44031 16.8874 8.76138 17.0637 7.93156 17.0637C7.43431 17.0637 7.08262 17.0319 6.84356 17V12.8382C7.27239 12.7756 7.70536 12.7461 8.13875 12.75C8.94306 12.75 9.46581 12.8945 9.87381 13.2026C10.3148 13.5299 10.591 14.0516 10.591 14.8006C10.591 15.6113 10.2946 16.1712 9.88656 16.5166ZM13.8125 13.5681H12.1848V14.5361H13.7062V15.3159H12.1848V17.0202H11.2221V12.7819H13.8125V13.5681ZM10.625 7.4375H9.5625V2.125L14.875 7.4375H10.625Z"
                            fill="#2D69F6" />
                    </svg>
                    <svg v-else width="17" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.75 0H2.15C1.57979 0 1.03292 0.226513 0.629724 0.629724C0.226513 1.03292 0 1.57979 0 2.15V19.35C0 19.9202 0.226513 20.467 0.629724 20.8703C1.03292 21.2735 1.57979 21.5 2.15 21.5H15.05C15.6202 21.5 16.167 21.2735 16.5703 20.8703C16.9735 20.467 17.2 19.9202 17.2 19.35V6.45L10.75 0ZM5.63193 15.5294C5.63193 16.7678 5.0396 17.2 4.08607 17.2C3.86032 17.2 3.56362 17.1613 3.3712 17.0957L3.47977 16.3035C3.6163 16.3486 3.79045 16.3809 3.98395 16.3809C4.39675 16.3809 4.65368 16.1938 4.65368 15.5166V12.7828H5.633L5.63193 15.5294ZM9.17297 15.1371C8.8365 15.4521 8.342 15.595 7.7615 15.595C7.6325 15.595 7.5164 15.5875 7.4261 15.5746V17.128H6.45215V12.8409C6.89182 12.7747 7.33623 12.7449 7.78085 12.7517C8.38607 12.7517 8.81822 12.8667 9.1074 13.0989C9.38583 13.3182 9.57287 13.6794 9.57287 14.1051C9.57287 14.5308 9.4299 14.892 9.17297 15.1371ZM13.8288 16.9291C13.5278 17.0323 12.9527 17.1742 12.3786 17.1742C11.5863 17.1742 11.0123 16.9743 10.6124 16.5873C10.2136 16.2132 9.99427 15.6466 9.99965 15.0081C10.0072 13.5633 11.0564 12.7388 12.4818 12.7388C13.0419 12.7388 13.4751 12.8495 13.6869 12.9516L13.4816 13.7374C13.2429 13.6342 12.9473 13.5504 12.47 13.5504C11.6519 13.5504 11.0316 14.0148 11.0316 14.9565C11.0316 15.852 11.5939 16.3809 12.398 16.3809C12.6248 16.3809 12.8043 16.3551 12.8828 16.3153V15.4058H12.212V14.6394H13.8288V16.9291ZM10.75 7.525H9.675V2.15L15.05 7.525H10.75Z"
                            fill="#2D69F6" />
                        <path
                            d="M7.8313 13.4932C7.63135 13.4932 7.4959 13.5125 7.42603 13.5319V14.8143C7.5088 14.8337 7.61308 14.8391 7.75605 14.8391C8.27635 14.8391 8.59885 14.5746 8.59885 14.1306C8.59885 13.7307 8.32258 13.4932 7.8313 13.4932Z"
                            fill="#2D69F6" />
                    </svg>

                </div>
                <div class="ml-5">
                    <p class="font-bold">{{ doc.description }}</p>
                    <p class="text-[12px]" :class="doc.expiry_date ? getExpiryStatus(doc.expiry_date) : ''">
                        {{
                            doc.expiry_date
                            ? "Expiring " +
                            moment(doc.expiry_date).format("MMM DD, YYYY")
                            : "-"
                        }}

                    </p>
                </div>
                <svg width="20" class="ml-auto cursor-pointer" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_8765_5184)">
                        <path
                            d="M10 0C4.48582 0 0 4.48582 0 10C0 15.5142 4.48582 20 10 20C15.5142 20 20 15.5142 20 10C20 4.48582 15.5142 0 10 0ZM13.0892 10.5892L8.9225 14.7558C8.76 14.9183 8.54668 15 8.33332 15C8.12 15 7.90664 14.9183 7.74414 14.7558C7.41832 14.43 7.41832 13.9033 7.74414 13.5775L11.3217 10L7.74418 6.4225C7.41836 6.09668 7.41836 5.57 7.74418 5.24418C8.07 4.91836 8.59668 4.91836 8.9225 5.24418L13.0892 9.41086C13.415 9.73668 13.415 10.2633 13.0892 10.5892Z"
                            fill="#2D69F6" />
                    </g>
                    <defs>
                        <clipPath id="clip0_8765_5184">
                            <rect width="20" height="20" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as moment from "moment-timezone";
export default {
    name: "CompanyComponent",
    data() {
        return {
            moment
        }
    },
    computed: {
        ...mapGetters(["company"]),
        companyAddress() {
            return `${this.company.address}<br/> ${this.company.city}, ${this.company.state.name} ${this.company.zipcode}`
        }
    },
    methods: {
        ...mapActions(['retrieveDocument']),
        async openDoc(id) {
            const doc = await this.retrieveDocument(id);
            window.location.href = doc.document
        },
        getExpiryStatus(date) {
            const currentDate = new Date();
            const expiryDate = new Date(date);
            const diffDays = parseInt(
                (expiryDate - currentDate) / (1000 * 60 * 60 * 24),
                10
            );
            let color;
            if (diffDays > 31) {
                color = "text-[#23262F]";
            }
            else if (diffDays <= 31 && diffDays >= 1) {
                color = "text-[#FEC701]";
            }
            else color = "text-[#FF0000]";
            return color
        }
    }
}
</script>